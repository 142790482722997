.sidebar-nav .has-arrow[aria-expanded="true"]::after,
.sidebar-nav li.active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after {
    transform: rotate(-135deg) translate(0, -50%);
}

.scroll-sidebar {
    position: relative;
    height: 100%;
}

.sidebar-nav ul .sidebar-item .sidebar-link {
    padding: 7px 35px 7px 8px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    position: relative;
    line-height: 25px;
    font-size: 16px;
    opacity: 0.6;
    color: #fff;
    border-left: 3px solid transparent;
}

.sidebar-nav ul .sidebar-item .sidebar-link .hide-menu {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sidebar-nav ul .sidebar-item .sidebar-link i {
    color: #fff;
    font-style: normal;
    line-height: 25px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    width: 30px;
    margin-right: 0.5rem;
    display: inline-block;
}

.sidebar-nav ul .sidebar-item .sidebar-link .badge {
    position: absolute;
    right: 34px;
    top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 14px;
}

.sidebar-nav ul .sidebar-item .sidebar-link.active,
.sidebar-nav ul .sidebar-item .sidebar-link:hover {
    opacity: 1;
}

.sidebar-nav ul .sidebar-item .sidebar-link.active {
    font-weight: 500;
}

.sidebar-nav ul .sidebar-item.selected > .sidebar-link {
    border-left: 3px solid #fff;
    opacity: 1;
}

.sidebar-nav ul .sidebar-item .first-level {
    padding: 0 0 10px 0;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item.active .sidebar-link {
    opacity: 1;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link {
    padding: 7px 15px 7px 37px;

    i {
        display: none;
        font-size: 14px;
    }
}

.sidebar-nav ul .nav-small-cap {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
    opacity: 1;
    text-transform: uppercase;
    font-weight: 500;
    padding: 2px 14px 4px 14px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.sidebar-nav ul .nav-small-cap i {
    line-height: 30px;
    margin: 0 5px;
    display: none;
}

.sidebar-nav .has-arrow {
    position: relative;
}

.sidebar-nav .has-arrow::after {
    position: absolute;
    content: "";
    width: 0.4em;
    height: 0.4em;
    margin-left: 10px;
    right: 15px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #fff;
    top: 50%;
    transform: rotate(-45deg) translate(0, -50%);
    transform-origin: top;
    transition: all 0.3s ease-out;
}

.mini-sidebar .navbar-brand .logo-icon {
    margin-right: 0 !important;
}

.left-sidebar {
    background: #fff;
    position: fixed;
    width: 240px;
    height: 100%;
    top: 0;
    padding-top: 70px;
    z-index: 10;
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
    transition: 0.2s ease-in;

    .sidebar-footer {
        background: #fff;
        border-top: 1px solid #e9ecef;
        position: fixed;
        bottom: 0;
        width: 240px;
        display: flex;
        align-items: center;
        z-index: 99;
        transition: 0.2s ease-in;

        a {
            padding: 15px;
            width: 33.333337%;
            text-align: center;
            font-size: 18px;
        }
    }

    .sidebar-footer a,
    .user-profile .profile-text a {
        color: #cfcfcf;
        transition: color 0.2 linear;
    }

    .sidebar-footer a:hover {
        color: #737b88 !important;
    }

    .sidebar-nav {
        font-family: $font-secondary;
        padding-bottom: 80px;
        padding-top: 10px;

        ul {
            background: #fff;
            margin: 0;
            padding: 0;

            li {
                list-style: none;
            }

            .nav-small-cap {
                color: #90a4ae;
                opacity: 1;
            }

            .nav-divider {
                background: rgba(120, 130, 140, 0.13);
                //background: rgba(255, 255, 255, 0.1);
                height: 1px;
                margin: 5px 0px;
                display: block;
            }

            .has-arrow::after {
                border-color: #54667a;
            }

            .sidebar-item {
                width: 240px;

                &.selected > .sidebar-link {
                    border-left: 4px solid $color-primary;
                    color: $color-primary;
                    opacity: 1;

                    i {
                        color: $color-primary;
                    }
                }

                .first-level .sidebar-item .sidebar-link.active {
                    color: #263238;

                    i {
                        color: #263238;
                    }
                }

                .sidebar-link {
                    color: #737b88;
                    opacity: 1;

                    &:hover {
                        color: #000;
                    }

                    i {
                        color: inherit;
                    }

                    &.active {
                        color: $color-primary;

                        i {
                            color: $color-primary;
                        }
                    }
                }
            }
        }
    }
}

#main-wrapper[data-sidebartype="iconbar"] .sidebar-nav ul .sidebar-item .sidebar-link {
    padding: 12px 15px;
}

#main-wrapper[data-sidebartype="iconbar"] .sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link {
    padding: 12px 15px;
}

#main-wrapper[data-sidebartype="iconbar"] .sidebar-nav ul .sidebar-item .sidebar-link {
    display: block;
    white-space: nowrap;
    text-align: center;
}

#main-wrapper[data-sidebartype="iconbar"] .sidebar-nav ul .sidebar-item .sidebar-link i {
    display: block;
    width: auto;
    margin: 0 auto;
}

#main-wrapper[data-sidebartype="overlay"] .left-sidebar {
    left: -240px;
}

#main-wrapper[data-sidebartype="overlay"].show-sidebar .left-sidebar {
    left: 0;
}

#main-wrapper[data-sidebartype="iconbar"] .sidebar-nav ul .sidebar-item .sidebar-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#main-wrapper[data-sidebartype="iconbar"] .left-sidebar,
#main-wrapper[data-sidebartype="iconbar"] .left-sidebar .sidebar-footer,
#main-wrapper[data-sidebartype="iconbar"] .sidebar-nav ul .sidebar-item {
    width: 180px;
}

@include media-breakpoint-up(md) {
    #main-wrapper[data-sidebartype="mini-sidebar"] .sidebar-nav ul .sidebar-item .sidebar-link {
        padding: 7px 35px 7px 12px;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .sidebar-nav ul .sidebar-item .first-level {
        padding: 0 0 10px;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"]
        .sidebar-nav
        ul
        .sidebar-item
        .first-level
        .sidebar-item
        .sidebar-link
        i {
        display: block;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar {
        width: 60px;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar .sidebar-footer {
        width: 0px;
        overflow: hidden;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar:hover {
        width: 240px;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar:hover .sidebar-nav .has-arrow:after,
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar:hover .sidebar-nav .hide-menu {
        display: block;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar:hover .sidebar-nav .nav-small-cap {
        justify-content: flex-start;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar:hover .sidebar-nav .nav-small-cap i {
        display: none;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar:hover .sidebar-nav .user-profile .profile-img {
        width: 50px;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar:hover .sidebar-nav .user-profile .profile-img::before {
        width: 50px;
        height: 50px;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar:hover .sidebar-nav .user-profile .u-dropdown {
        display: block;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar:hover .sidebar-nav ul .sidebar-item .sidebar-link {
        padding: 7px 35px 7px 12px;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar:hover .sidebar-nav ul .sidebar-item .first-level {
        padding: 0 0 10px 0;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"]
        .left-sidebar:hover
        .sidebar-nav
        ul
        .sidebar-item
        .first-level
        .sidebar-item
        .sidebar-link
        i {
        display: none;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar:hover .sidebar-footer {
        display: flex;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"]
        .sidebar-nav
        ul
        .sidebar-item
        .first-level
        .sidebar-item
        .sidebar-link {
        padding: 7px 15px 7px 14px;
    }

    #main-wrapper[data-sidebartype="mini-sidebar"]
        .left-sidebar:hover
        .sidebar-nav
        ul
        .sidebar-item
        .first-level
        .sidebar-item
        .sidebar-link {
        padding: 7px 15px 7px 14px;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .sidebar-nav .has-arrow:after,
    #main-wrapper[data-sidebartype="mini-sidebar"] .sidebar-nav .hide-menu {
        display: none;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .sidebar-nav .nav-small-cap {
        justify-content: center;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .sidebar-nav .nav-small-cap i {
        display: block;
        opacity: 0.6;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .sidebar-nav .user-profile .profile-img {
        width: 35px;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .sidebar-nav .user-profile .profile-img::before {
        width: 35px;
        height: 35px;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .sidebar-nav .user-profile .u-dropdown {
        display: none;
    }
}

@include media-breakpoint-down(sm) {
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar,
    #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar .sidebar-footer {
        left: -240px;
    }

    #main-wrapper.show-sidebar .left-sidebar,
    #main-wrapper.show-sidebar .left-sidebar .sidebar-footer {
        left: 0;
    }
    #main-wrapper.show-sidebar .left-sidebar .sidebar-footer a {
        font-size: 16px;
        padding: 8px;
    }
    .sidebar-nav {
        padding-bottom: 54px;
    }
}
