.error-box {
    position: fixed;
    background: url(/../assets/images/background/error-bg.jpg) no-repeat bottom center #fff;
    height: 100%;
    width: 100%;
}

.error-box .error-title {
    font-size: 210px;
    font-weight: 900;
    text-shadow: 4px 4px 0 #fff, 6px 6px 0 #263238;
    line-height: 210px;
}

.error-box .error-body {
    padding-top: 5%;
}

@include media-breakpoint-between(md, lg) {
    .error-box .error-title {
        font-size: 150px;
        line-height: 150px;
    }
}

@include media-breakpoint-between(sm, md) {
    .error-box .error-title {
        font-size: 70px;
        line-height: 70px;
    }
}
