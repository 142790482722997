.noUi-connect {
    background: #28a745 !important;
}

.noUi-horizontal .noUi-tooltip {
    transform: translate(-50%, 50%) !important;
    bottom: 50% !important;
}

.noUi-handle:after,
.noUi-handle:before {
    display: none !important;
}
