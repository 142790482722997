.login-bcg {
    //background: url("/assets/images/background/login-bg.jpg") no-repeat top center;
    //background-size: cover;
    background-color: #000;
}

.auth-wrapper {
    position: relative;
    min-height: 100vh;
    margin: 0 auto;
    max-width: 420px;
    width: 100%;
    background-color: #fff;
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
}

.auth-wrapper .auth-logo {
    position: absolute;
    top: 3rem;
    left: 5rem;
    right: 5rem;
    z-index: 10;
}

.auth-wrapper .auth-logo img {
    display: block;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.credits{
    position: absolute;
    bottom: 3rem;
    z-index: 10;
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    img{
        max-width: 120px;
        transition: 0.3s;
    }
    span{
        display: block;
        font-size:14px;
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: underline;
            img{
                transform: scale(0.8);
            }
        }
    }
}

.auth-wrapper .auth-box {
    border-radius: 0;
    width: 100%;
    background-color: #fff;
    z-index: 100;
}

.auth-wrapper .auth-box.on-sidebar {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}

.auth-wrapper #recoverform {
    display: none;
}
