$theme-colors: (
    info: #000,
);

$fa-font-path: "../webfonts";

$font-primary: "Roboto", sans-serif;
$font-secondary: "Rubik", sans-serif;

$color-primary: #4e4e4e;
$color-primary-dark: #000;
$color-secondary: #4e4e4e;
$color-secondary-dark: #000;

$pretty--color-default: #d3d3d3;
$pretty--color-primary: #000;

:root {
    --animate-duration: 500ms;
    --animate-delay: 0.2s;
}
