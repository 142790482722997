.vize .card.bg-success {
    background-color: rgba(57, 196, 73, 0.15) !important;
}
.vize .card.bg-primary {
    background-color: rgba(0, 123, 255, 0.15) !important;
}
.vize .card.bg-info {
    background-color: rgba(23, 162, 184, 0.15) !important;
}
.vize .fa-trophy {
    color: rgba(255, 255, 255, 0.75) !important;
}
.vize .confirmSmazatObrazek,
.confirmSmazatVizi,
.confirmSplnenaVize {
    cursor: pointer;
}
