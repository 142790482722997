.naseptavac-container {
    display: none;
    position: absolute;
    top: 100%;
    z-index: 1100;
    left: 0;
    right: 0;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    height: 3rem;
}

.naseptavac-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1200;
}

.naseptavac-close .fas {
    padding: 1rem;
}

.naseptavac-item {
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.2;
    color: #54667a;
    background-color: #fff;
    border-left: 1px solid #e9ecef;
    border-right: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
}

.naseptavac-item p {
    padding: 0;
    margin: 0.25rem 0;
}

.naseptavac-item .headline {
    font-size: 1rem;
    font-weight: 500;
    color: #35404d;
}

.naseptavac-container .naseptavac-item:not(:first-child) {
    border-top: 1px solid #e9ecef;
}

.naseptavac-item:hover {
    background-color: #f2f7f8;
    cursor: pointer;
}

.naseptavac-placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    background-color: rgb(33, 37, 41);
    opacity: 0.5;
    animation: placeholder-glow 2s ease-in-out infinite;
}
