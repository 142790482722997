.ps__thumb-y {
    right: 0;
    border-radius: 0;
}

.ps__rail-y,
.ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
    width: 8px;
}
