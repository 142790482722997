.modal-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: none;
    background: rgba(0, 0, 0, 0.25);

    .cv-spinner {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .spinner {
        width: 4rem;
        height: 4rem;
        border: 4px #ddd solid;
        border-top: 4px #3ba2b9 solid;
        border-radius: 50%;
        animation: sp-anime 0.8s infinite linear;
    }
}

.bg-modal-light {
    background-color: #f8f9fa !important;
    margin: 1rem 0;
    padding: 1rem 15px 1rem;
}

.col-form-label {
    padding: 0.5rem 0 0;
}

.input-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.modal,
.modal-open {
    padding-right: 0 !important;
}

/*Modal open jump fix*/
.modal-open {
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 0 !important;
}

.modal-dialog {
    .modal-title {
        margin-top: 0;
        font-size: 1.25rem;
        color: $color-secondary;
    }

    .modal-footer {
        padding: 1.25rem 0 0.75rem;
    }
}

.modal-full-width {
    width: 95%;
    max-width: none;
}

.modal-top {
    margin: 0 auto;
}

.modal-right {
    position: absolute;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    margin: 0;
    background-color: #fff;
    align-content: center;
    transform: translate(25%, 0) !important;

    button.close {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1;
    }
}

.modal.show .modal-right {
    transform: translate(0, 0) !important;
}

.modal-bottom {
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    align-content: center;
}

.modal-colored-header {
    color: #fff;
    border-radius: 0;
}

.pretty.p-icon .state .icon:before {
    font-size: 1rem !important;
}

.pretty.p-bigger label {
    text-indent: 1.875rem !important;
}

.pretty .state label:before {
    background: #fff !important;
}

.modal-body .input-row {
    padding: 0 1rem;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: scroll !important;
}

.modal-colored-header .close {
    color: #fff !important;
}

.modal-filled {
    color: #fff;

    .modal-header {
        background-color: rgba(255, 255, 255, 0.07);
    }

    .modal-footer,
    .modal-header {
        border: none;
    }

    .close {
        color: #fff !important;
    }
}

.modal-errors {
    margin: 0;
    padding: 0;

    p {
        line-height: 1;
        font-size: 1rem;
        color: #dc3545;
        margin: 0;
        padding: 0.25rem 0 1rem;
    }
}

@include media-breakpoint-down(md) {
    .modal-open .modal {
        padding-right: 0 !important;
    }
    .modal .form-group .text-right {
        text-align: left !important;
    }
    .modal .col-sm-3 + .col-sm-3 {
        margin-bottom: 0.5rem;
    }
}
