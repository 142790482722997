.topbar .mega-dropdown .dropdown-menu {
    border-radius: 0;
}

.topbar .dropdown-menu .with-arrow > span {
    transform: rotate(45deg);
}

.top-navbar .navbar-header {
    transition: 0.2s ease-in;
}

.top-navbar .navbar-header {
    position: fixed;
    z-index: 10;
}

.topbar {
    position: fixed;
    width: 100%;
    height: 70px;
    background: #fff;
    z-index: 50;
    transition: 0.2s ease-in;
    box-shadow: 1px 0 7px rgba(0, 0, 0, 0.05);
}

.topbar .navbar-collapse {
    padding: 0 10px 0 0;
}

.topbar .top-navbar {
    min-height: 70px;
    padding: 0;
}

.topbar .top-navbar .dropdown-toggle::after {
    display: none;
}

.topbar .top-navbar .navbar-header {
    line-height: 70px;
    height: 70px;
}

.topbar .top-navbar .navbar-header .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin: 0;
    z-index: 25;
    position: relative;
}

.topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
    display: none;
}

.topbar .top-navbar .navbar-header .navbar-brand .logo-icon {
    display: inline-block;
    margin-right: 5px;
    overflow: unset;
}

.topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
    line-height: 70px;
    height: 70px;
    padding: 0 0.75rem;
    font-size: 17px;
}

.topbar .top-navbar .navbar-nav > .nav-item {
    transition: 0.2s ease-in;
}

.topbar .top-navbar .navbar-nav > .nav-item:hover {
    background: rgba(0, 0, 0, 0.05);
}

.topbar .mailbox,
.topbar .user-dd {
    width: 300px;
}

.topbar .nav-toggler,
.topbar .topbartoggler {
    color: #fff;
    padding: 0 15px;
}

.app-search {
    position: relative;
    margin-top: 13px;
    margin-right: 10px;
    display: inline-block;
}

.app-search input {
    width: 200px;
    font-size: 14px;
    transition: width 0.2s linear;
}

.app-search input:focus {
    width: 240px;
}

.app-search .srh-btn {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    font-size: 14px;
}

.topbar .mega-dropdown {
    position: static;
}

.topbar .mega-dropdown .dropdown-menu {
    padding: 30px;
    width: 100%;
    max-height: 480px;
    overflow: auto;
}

.topbar .dropdown-menu {
    z-index: 600;
    padding-top: 0;
    border: 0;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
}

.topbar .dropdown-menu .with-arrow {
    position: absolute;
    top: -10px;
    width: 40px;
    height: 10px;
    overflow: hidden;
}

.topbar .dropdown-menu .with-arrow > span {
    width: 15px;
    height: 15px;
    position: absolute;
    content: "";
    top: 3px;
    left: 15px;
    background-color: #fff;
    border-radius: 6px 0 0 0;
}

.topbar .dropdown-menu.dropdown-menu-right .with-arrow {
    right: 0;
}

.topbar .dropdown-menu.dropdown-menu-right .with-arrow > span {
    right: 20px;
    left: auto;
}

.topbar .top-navbar .navbar-header {
    background: #fff;
}

.topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
    display: inline;
    overflow-wrap: break-word;
}

.topbar .top-navbar .navbar-header .navbar-brand .light-logo {
    display: none;
}

.topbar .top-navbar .navbar-header .nav-toggler,
.topbar .top-navbar .navbar-header .topbartoggler {
    color: #54667a;
}

.topbar .mailbox {
    width: 370px;
}

.topbar .navbar-collapse {
    padding: 0 10px;
}

#main-wrapper[data-sidebartype="iconbar"] .topbar .top-navbar .navbar-header {
    width: 180px;
}

#main-wrapper[data-sidebartype="overlay"] .topbar .top-navbar .navbar-header {
    width: 240px;
}

@include media-breakpoint-up(md) {
    .top-navbar .navbar-header {
        width: 240px;
    }
    .top-navbar .navbar-collapse {
        margin-left: 240px;
    }
    #main-wrapper[data-sidebartype="full"] .topbar .top-navbar .navbar-header {
        width: 240px;
    }
    #main-wrapper[data-sidebar-position="fixed"][data-sidebartype="full"] .topbar .top-navbar .navbar-collapse,
    #main-wrapper[data-sidebar-position="fixed"][data-sidebartype="overlay"] .topbar .top-navbar .navbar-collapse {
        margin-left: 240px;
    }
    #main-wrapper[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] .topbar .top-navbar .navbar-collapse {
        margin-left: 60px;
    }
    #main-wrapper[data-sidebar-position="fixed"][data-sidebartype="iconbar"] .topbar .top-navbar .navbar-collapse {
        margin-left: 180px;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .topbar .top-navbar .navbar-header {
        width: 60px;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .topbar .top-navbar .navbar-header .logo-text {
        display: none;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .topbar .top-navbar .navbar-header.expand-logo {
        width: 240px;
        z-index: 99;
    }
    #main-wrapper[data-sidebartype="mini-sidebar"] .topbar .top-navbar .navbar-header.expand-logo .logo-text {
        display: inline-block;
    }
    #main-wrapper[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] .topbar .top-navbar .navbar-collapse {
        margin-left: 60px;
    }
}

@include media-breakpoint-down(sm) {
    .top-navbar .navbar-collapse,
    .top-navbar .navbar-collapse {
        position: absolute;
        top: 0;
        right: 0;
    }

    .top-navbar .navbar-collapse {
        margin-left: 240px;
    }

    .top-navbar .navbar-collapse.collapsing,
    .top-navbar .navbar-collapse {
        display: flex;
        top: 0 !important;
        background: transparent !important;
        z-index: 20;
    }

    .top-navbar .navbar-nav {
        flex-direction: row;
    }
    .top-navbar .navbar-nav .dropdown {
        position: static;
    }
    .top-navbar .navbar-nav > .nav-item > .nav-link {
        padding: 0 10px;
    }
    .top-navbar .navbar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .top-navbar .dropdown-menu {
        position: fixed;
        top: 70px;
        left: 0;
        right: 0;
        width: 100vw;
    }
    .top-navbar .dropdown-menu .with-arrow {
        display: none;
    }
    .app-search .srh-btn {
        font-size: 18px;
        top: 6px;
        width: 18px;
        height: 18px;
    }
    .top-navbar .navbar-header .navbar-brand {
        display: none !important;
    }
}

@include media-breakpoint-up(lg) {
    .top-navbar .navbar-header {
        background: #fff;
    }
}
