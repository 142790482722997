.page-breadcrumb {
    padding: 25px 8px 0;

    .page-title {
        margin-bottom: 0;
    }

    .breadcrumb {
        padding: 0;
        margin: 0;
        background: 0 0;
        font-size: 16px;

        .breadcrumb-item + .breadcrumb-item::before {
            content: "\f105";
            font-family: "Font Awesome 6 Free";
            color: #ced4da;
            font-size: 12px;
            display: block !important;
        }
    }
}

.breadcrumb-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    a:hover {
        text-decoration: underline;
    }
}

.breadcrumb-buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: -0.5rem;

    .btn {
        white-space: nowrap;
        margin-top: 0.5rem;
    }
}

@include media-breakpoint-up(md) {
    .page-breadcrumb {
        padding: 25px 30px 0;
    }
}
