.el-element-overlay .white-box {
    padding: 0;
}

.el-element-overlay .el-card-item .el-overlay-1 {
    cursor: default;
}

.el-element-overlay .el-card-item .el-overlay-1 img {
    height: auto;
    transition: all 0.4s linear;
}

.el-element-overlay .el-card-item .el-overlay-1:hover img {
    transform: scale(1.2) translateZ(0);
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    transform: translateY(-50%) translateZ(0);
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info .el-item .el-link {
    padding: 12px 15px 10px;
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info .el-item .el-link:hover {
    border-color: $color-primary;
    background: $color-primary;
}

.el-element-overlay .el-card-item .el-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.4s ease-in-out;
}

.el-element-overlay .el-card-item .el-overlay-1:hover .el-overlay {
    opacity: 1;
    transform: translateZ(0);
}

.el-element-overlay .el-card-item .el-overlay-1 .scrl-dwn {
    top: -100%;
}

.el-element-overlay .el-card-item .el-overlay-1 .scrl-up {
    top: 100%;
    height: 0;
}

.el-element-overlay .el-card-item .el-overlay-1:hover .scrl-dwn {
    top: 0;
}

.el-element-overlay .el-card-item .el-overlay-1:hover .scrl-up {
    top: 0;
    height: 100%;
}
