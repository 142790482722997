.table-sorter-container {
    display: none;
    font-family: $font-primary;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: 5px 0;
    clear: both;
    text-align: left;
    overflow: auto;
}

.table-sorter-save-container {
    display: none;
    margin-bottom: 0.5rem;
}

.table-sorter-toggle {
    cursor: pointer;
    transition: opacity 0.15s linear;
}

.table-sorter-toggle:hover {
    opacity: 0.66;
}

.table-sorter-item {
    vertical-align: top;
    display: inline-block;
    margin: 0.25rem;
    padding: 0.25rem 0.75rem;
    color: #fff;
    background-color: #868e96;
    border-radius: 6px;
    font-size: 1rem;
    line-height: 1;
    font-weight: 500;
    cursor: pointer;
}

.table-sorter-item i.fa-eye-slashed {
    opacity: 0.5;
}
