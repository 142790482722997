.roles-menu {
    margin-top: 20px;
}
.roles-menu .nav {
    background-color: #fff;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: block;
}
.roles-menu .nav li {
    display: flex;
    align-items: center;
}
.roles-menu .nav > li a {
    border-left: 3px solid transparent;
    color: #333;
    position: relative;
    display: block;
    padding: 10px 15px;
}
.roles-menu .nav > li.active a {
    border-color: $color-primary;
    color: $color-primary;
}
.roles-menu .nav > li a:hover {
    border-color: $color-primary;
}

.roles-menu .panel > .list-group,
.roles-menu .panel > .panel-collapse > .list-group {
    margin-bottom: 0 !important;
}

.roles-menu .list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
}

.role-action {
    margin-left: auto;
    display: none;
}

.role-action a {
    border: none !important;
    padding: 0px !important;
    display: inline-flex !important;
}
.roles-menu .nav > li:hover .role-action {
    display: block;
}
.role-permission {
    margin: -35px 5px 20px 0px;
}
