#main-wrapper {
    width: 100%;
    overflow: hidden;
}

.page-wrapper {
    position: relative;
    background: #f2f7f8;
    transition: 0.2s ease-in;
    padding-top: 70px;
}

.page-wrapper > .container-fluid,
.page-wrapper > .container-lg,
.page-wrapper > .container-md,
.page-wrapper > .container-sm,
.page-wrapper > .container-xl {
    padding: 20px 30px;
    min-height: calc(100vh - 180px);
}

#main-wrapper[data-sidebartype="full"] .page-wrapper {
    margin-left: 240px;
}

#main-wrapper[data-sidebartype="iconbar"] .page-wrapper {
    margin-left: 180px;
}

.page-wrapper > .container-fluid {
    padding: 20px 0px;
}

@include media-breakpoint-up(md) {
    #main-wrapper[data-sidebartype="mini-sidebar"] .page-wrapper {
        margin-left: 60px;
    }

    .page-wrapper > .container-fluid {
        padding: 1rem;
    }
}
