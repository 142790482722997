.profiletimeline {
    position: relative;
    padding-left: 40px;
    margin: 10px 10px 0 30px;
    border-left: 1px solid #e9ecef;
}

.profiletimeline .sl-left {
    margin-left: -60px;
    z-index: 1;
    margin-right: 15px;
}

.profiletimeline .sl-left img {
    max-width: 40px;
}

.profiletimeline .sl-item {
    margin-top: 8px;
    display: flex;
    margin-bottom: 30px;
}

.profiletimeline .sl-date {
    font-size: 12px;
    color: #90a4ae;
}

.sl-date {
    font-size: 10px;
    color: #90a4ae;
}
